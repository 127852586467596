import "@fontsource/patua-one";
import "@fontsource/montserrat";
import "@fontsource/playfair-display";
import "@fontsource/open-sans";

export const variables = {
    //Color Variables
    primaryDark: "#161616",
    primaryLight: "#E6E6E6",
    primaryWhite: "#fdfdfe",

    primaryGray: "#292929",
    primaryLightGray: "#7f7f7f",

    primaryBlue: "#0077B6",
    secondaryBlue: "#0096C7",
    primaryGreen: "#48CAE4",
    primaryRed: "#e76f51",
    primaryOrange: "#f4a261",

    //Typography Variables
    primaryFont: "'Open Sans', sans-serif;",
    // headingFont: "'Source Sans Pro', sans-serif",
    // nameFont: "'Julius Sans One', sans-serif",
    // nameFont: "'Raleway', sans-serif",
    font: "'Patua One', cursive",
    // font: "'Salsa', cursive",
    // secondaryFont: "'Roboto Slab', serif",
    buttonFont: "'Playfair Display', serif",
    headingFont: "'Montserrat', sans-serif",

    //Other Variables
    boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",

    //Media Query Breakpoints
    small: "575.98px", 
    medium: "767.98px",
    large: "991.98px",
    desktop: "1199.98px"
}